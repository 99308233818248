






























































import Vue from 'vue';
import { Upload } from '@/http';

const TYPES = [
  'image/jpeg',
  'image/png',
];

interface Validation {
  [index: string]: string|null,
  type: 'notOptional'|'in'|null,
}

export default Vue.extend({
  data() {
    return {
      error: null,
      files: [] as FileList|[],
      isSuccess: false,
      progress: {
        max: 1,
        value: 0,
      },
      validation: null as string|null,
    };
  },
  computed: {
    file(): File|null {
      return this.files[0] || null;
    },
    fileAccept() {
      return TYPES.join(',');
    },
    progressValue(): number|null {
      const { value } = this.progress;

      return value > 0 ? value : null;
    },
  },
  methods: {
    async submit() {
      if (this.validate()) return;
      if (!this.file) return; // Type safety

      const data = await this.$store.dispatch('account/ChangeIcon', {
        form: {
          type: this.file.type, // 'image/jpg'|'image/png'
        },
      }).catch((e) => { this.error = e; });

      await Upload(
        data.data,
        this.file,
        this.onUploadProgress,
      ).catch((e) => { this.error = e; });

      if (!this.error) this.isSuccess = true;
    },
    onUploadProgress({ total, loaded }: ProgressEvent) {
      console.debug('[UploadProgress]', loaded, '/', total);
      this.progress.max = total;
      this.progress.value = loaded;
    },
    validate(validation: string|null = null): boolean {
      const { file } = this;
      let v = validation;

      if (!file) {
        v = 'notOptional';
      } else if (!TYPES.includes(file.type)) {
        v = 'in';
      } else if (file.size > 2_000_000) { // 100 kB
        v = 'max';
      }

      this.validation = v;

      return !!this.validation;
    },
  },
});
