












import Vue from 'vue';
import NameForm from '@/components/account/NameForm.vue';
import IconUpload from '@/components/account/IconUpload.vue';

export default Vue.extend({
  components: {
    NameForm,
    IconUpload,
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
