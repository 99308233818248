


































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';

interface VuexBindings {
  account: ApiResource.Account,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  data() {
    return {
      error: null,
      form: this.$store.getters['account/Account'].name,
      loading: false,
      validation: null as string|null,
    };
  },
  computed: {
    ...mapGetters('account', {
      account: 'Account',
    }),
  },
  methods: {
    async submit(): Promise<void> {
      if (this.validate()) return;

      if (this.form === this.account.name) return;

      this.loading = true;

      await this.$store.dispatch('account/PatchAccount', {
        form: {
          name: this.form,
        },
      }).then(() => {
        this.$toastSuccess(this.$t('success').toString());
      }).catch((e) => {
        this.$et(e);
        this.validation = e.response?.data?.error?.fields.name;
      });

      this.loading = false;
    },
    validate(validation: string|null = null): boolean {
      const { form: F } = this;
      let v = validation;

      if (!F) {
        v = 'notOptional';
      } else if (F.length > 25) {
        v = 'length';
      }

      this.validation = v;
      return v !== null;
    },
  },
});
